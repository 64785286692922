import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppDataService } from 'src/app/core/app-data/app-data.service';
type Coverage = { [key: string]: string }   

@Injectable()
export class CoverageTermService {

  constructor(private appDataService: AppDataService) { }

  public getCoverageOptions(program: string, saleType: string, manufacturer: string, equipType: string, model: string,hours: number,serialNumber: string | null, ratingType: string, warranties: Array<{term: number, hours: number, coverage: string}> = []): Observable<Coverage[]> {
      // * encodeURIComponent: ensure special characters (&*%+|) are being sending properly
      const stringWarranties = warranties.map(w => `${w.term},${w.hours},${encodeURIComponent(w.coverage)}`).join('|').toString();
        return this.appDataService.get<Coverage[]>('api/v1/espp/coverage_terms',{
          program,
          quote_type: saleType,
          manufacturer,
          rating_type: ratingType,
          equip_type: equipType,
          model,
          hours,
          serial_number: serialNumber ?? '',
          warranties: stringWarranties
        });
    }
}
