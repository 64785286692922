import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TtmLimitService {

    public ttmON = new BehaviorSubject<boolean>(false)
    public ttmMfr = new BehaviorSubject<boolean>(false)
    public ttmLimits = new BehaviorSubject<{ [key: string]: string | number }[]>([])
    public ttmClaimLimits = new BehaviorSubject<{ [key: string]: string | number }[]>([])
    public ttmCost = new BehaviorSubject<{ [key: string]: string | number }[]>([])

    constructor(private http: HttpClient) { }

    public getTtmLimits(esppTerm: number, mfrTerm: number, coverages: string[]): Observable<{ [key: string]: string }[]> {
        let params = new HttpParams();
        params = params.append('espp_term', '' + esppTerm)
        params = params.append('mfr_term', '' + mfrTerm)
        params = params.append('key', '' + 'limit')
        params = params.append('coverages', coverages.map(coverage => coverage.replace(/[+]/gm,'*')).join(','))
        params = params.append('limit', '0')
        params = params.append('total_limit', '0')
        return this.http.get<{ [key: string]: string }[]>('api/v1/espp/ttm', { params })
            .pipe(
                catchError(errorResponse => of([])),
                tap(limits => this.ttmLimits.next(limits))
            )
    }

    public getTotalLimits(esppTerm: number, mfrTerm: number, coverages: string[], limit: number) {
        let params = new HttpParams();
        params = params.append('espp_term', '' + esppTerm)
        params = params.append('mfr_term', '' + mfrTerm)
        params = params.append('key', '' + 'claim_limit')
        params = params.append('coverages', coverages.map(coverage => coverage.replace(/[+]/gm,'*')).join(','))
        params = params.append('limit', '' + limit)
        params = params.append('total_limit', '0')
        return this.http.get<{ [key: string]: string }[]>('api/v1/espp/ttm', { params })
            .pipe(
                catchError(errorResponse => of([])),
                tap(limits => this.ttmClaimLimits.next(limits.filter( tlimit => parseInt(tlimit.total_limit) > limit)))
            )
    }

    public getCosts(esppTerm: number, mfrTerm: number, coverages: string[], limit: number, totalLimit: number) {
        let params = new HttpParams();
        params = params.append('espp_term', '' + esppTerm)
        params = params.append('mfr_term', '' + mfrTerm)
        params = params.append('key', '' + 'cost')
        params = params.append('coverages', coverages.map(coverage => coverage.replace(/[+]/gm,'*')).join(','))
        params = params.append('limit', '' + limit)
        params = params.append('total_limit', '' + totalLimit)
        return this.http.get<{ [key: string]: string }[]>('api/v1/espp/ttm', { params })
            .pipe(
                catchError(errorResponse => of([])),
                tap(costs => this.ttmCost.next(costs))
            )
    }

}
