import { HttpClient, HttpErrorResponse, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { Coverage, Equipment, Rate, RateData } from 'src/app/core/types/equipment';
import { Dealership, MPSSyncGroup, Quote } from 'src/app/core/types/quote';
import { QuoteResponse } from 'src/app/core/types/responses/QuoteResponse';
import { HttpStatusCodeRange, HttpStatusCodeRangeUtil } from 'src/app/core/utils/HttpStatusCodeRange';
import { Permission } from 'src/app/modules/admin/role-managament/types/Permission';
import { DialogServiceService } from 'src/app/modules/epg-dialog/dialog-service.service';
import { QuoteFileUploadComponent } from 'src/app/modules/espp/quotes/components/quote-file-upload/quote-file-upload.component';
import { User } from '../models/User';
import { FileCategory } from './claim/claim.service';
import { QuoteCoverageService } from './espp/quote/quote-coverage.service';
import { EpgDialogService } from '../components/epg-dialog/epg-dialog.service';
import { CoverageTermService } from 'src/app/modules/espp/modules/espp-quoter/services/coverage-term/coverage-term.service';
import { environment } from 'src/environments/environment';

export const createUpdateCoverageRequest = (coverageId: number, retailCost: number, markup: { markup: number; type: MarkupType; }): { equipment_coverage_id: number; retail_cost: number; markup_type: any; markup_amount: number; } => {
    return {
        equipment_coverage_id: coverageId,
        retail_cost: retailCost,
        markup_type: markup.type,
        markup_amount: markup.markup
    };
}

type Subset<T extends U, U> = U;

export enum MarkupType {
    fixed = 'Flat Fee',
    percentage = 'Percentage'
}

export type QuoteItem = {
    [x: string]: any,
    id: number,
    quote_expiration: string,
    date_submitted: string | null,
    quote_number: string,
    include_ttm: boolean,
    is_presale: boolean,
    customer: {
        id: number,
        name: string
    } | null,
    equipment: Array<{
        id: number,
        manufacturer: string,
        model: string,
        equipment_type: string,
        year: string,
        equipment_name: string
    }>,
    documents: Array<{
        name: string,
        document_key: string,
        path: string
    }>
}
export interface EquipmentCoverageData {
    StatusID?: number,
    CovType?: string,
    EquipCovType?: string | null,
    EffectiveDate?: string | Date,
    ExpirationDate?: string | Date,
    InsureTerm?: number,
    TotalTerm?: number,
    BeginHours?: number,
    Hours?: number,
    DateOfSale?: string | Date,
    UseType?: string,
    QuoterType?: string,
    InsureAmount?: number,
    Deductible?: number
}
export interface QuoteRequestData {
    volvo_ship_date: string | null,
    customer_name: string | null,
    quote_type: string,
    sale_type: string
    use_type: string,
    manufacturer: string,
    equipment_type: string,
    equipment_status: string | null,
    model: string,
    model_year: number,
    warranty_start_date: string | null,
    warranty_end_date: string | null,
    warranty_term: { term: number, hours: number, coverage: string }[],
    current_hours: number,
    plan_term: { term: string, hours: string },
    retail_value: number | null,
    rating_type: string,
    is_presale: boolean,
    dealership_id?: number,
    salesperson_id?: number,
    serial_number?: string,
    existing_coverage?: string,
    coverage_period?: string,
    coverage_type?: string,
    service_fee_or_deductible?: string,
    plan_number?: string,
}

@Injectable({
    providedIn: 'root'
})
export class QuoteService {

    public formSubmitted = new BehaviorSubject<boolean>(false);
    public quotesQueue: BehaviorSubject<QuoteItem[]> = new BehaviorSubject<QuoteItem[]>([]);
    public currentQuote: BehaviorSubject<Quote | QuoteModel | null> = new BehaviorSubject<Quote | QuoteModel | null>(null);
    public isDetailsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private dialogService: DialogServiceService,
        private epgDialog: EpgDialogService,
        private userService: AuthenticationService,
        private coverageService: QuoteCoverageService,
        private modal: MatDialog) { }

    public getQuotes(page: number = 1, submitted: boolean = false, filters?: string): Observable<QuoteResponse> {
        let params = new HttpParams();
        params = params.append('type', submitted ? 'quotes' : 'submitted');
        return this.http.get<QuoteResponse>(`api/v1/espp/quotes?page=${page}${filters!==undefined ? filters : ''}`, { params })
    }

    public getQuote(id: number) {
        return this.http.get<Quote>(`api/v1/espp/quotes/${id}`)
            .pipe(
                tap(quote => {
                    // const model = new QuoteModel().deserialize(quote)
                    const model = new QuoteModel(quote)
                    this.ttmIncluded.next(quote.include_ttm);
                    this.setTTMCOverages(quote);
                    this.setTTMMFRCoverages(quote);
                    this.setEwCoverages(quote);
                })
            )
    }

    public storeQuote(quote: QuoteRequestData) {
        return this.http.post<Quote>(`api/v2/quotes`, quote)
            .pipe(
                tap(quote => {
                    const currentValue = this.quotesQueue.value;
                    const updatedValue = [...[this.createQueueItem(quote)], ...currentValue];
                    this.quotesQueue.next(updatedValue);
                    this.ttmIncluded.next(quote.include_ttm);
                    this.setTTMCOverages(quote);
                    this.setTTMMFRCoverages(quote);
                    this.setEwCoverages(quote)
                })
            )
    }

    public errorMessage = new Subject<string[]>();
    public updateQuote(quote: object, id: number): Observable<Quote> {
        return this.http.patch<Quote>(`api/v2/quotes/${id}`, quote)
            .pipe(
                catchError(errorResonse => {
                    const range: HttpStatusCodeRange = HttpStatusCodeRangeUtil.getRange(errorResonse.status);
                    switch (range) {
                        case HttpStatusCodeRange.CLIENT_ERROR_RANGE:
                            const baseError = errorResonse.error.message;
                            if (errorResonse.status === 422) {
                                this.errorMessage.next([
                                    ...[baseError]
                                ]);
                                break;
                            }

                            if (errorResonse.status === 400) {
                                this.errorMessage.next([
                                    ...[baseError]
                                ]);
                                break;
                            }

                            const requestID = (errorResonse.headers.get('request-id')).split('-');
                            this.errorMessage.next([
                                ...[`${baseError} Please contact the program administrator at (866) 408-2881 for assistance. Error ID: ${requestID[0]}`]
                            ]);
                            break;
                        default:
                            break;
                    }
                    return throwError(errorResonse);
                }),
                tap(quote => {
                    const queue = this.quotesQueue.value
                    const currentQuote: Quote | null = this.currentQuote.value
                    if (quote.id === currentQuote?.id) {
                        this.currentQuote.next(quote);
                    }
                    const quoteIndex: number = queue.findIndex(item => item.id === quote.id);
                    console.log(quoteIndex)
                    if (quoteIndex >= 0) {
                        queue[quoteIndex] = this.createQueueItem(quote)
                        this.quotesQueue.next(queue);
                        console.log(queue[quoteIndex])
                        this.quoteItemUpdate.emit();
                    }
                    this.ttmIncluded.next(quote.include_ttm);
                    this.setTTMCOverages(quote);
                    this.setTTMMFRCoverages(quote);
                    this.setEwCoverages(quote);
                    console.log(this.ewCoverages.value)
                })
            )
    }
    public quoteItemUpdate: EventEmitter<number> = new EventEmitter();

    public lockinPresale(presaleNumber: string): Observable<Quote> {
        const quote = this.currentQuote.value;
        if (quote) {
            return this.updateQuote(this.makeQuoteResponsePresale(presaleNumber), quote.id)
                .pipe(
                    tap(quote => {
                        const quoteQueue = this.quotesQueue.value;
                        quoteQueue.map(item => {
                            if (quote.id == item.id) {
                                item.is_presale = quote.is_presale;
                                item.badgeType = 'presale';
                            }
                            return item;
                        })
                    })
                );
        }

        throw new Error('Unit is not a presale unit.');
    }
    private formatDate(date: Date | null): string | null {
        if (date == null) return null;
        const pad = (n) => n < 10 ? "0" + n : n;
        let d = date
        let dash = "-"
        let colon = ":"
        return d.getFullYear() + dash +
            pad(d.getMonth() + 1) + dash +
            pad(d.getDate()) + " " +
            pad(0) + colon +
            pad(0) + colon +
            pad(0)
    }
    private makeQuoteResponsePresale(presaleValue: string): object {
        const quote = this.currentQuote.value;

        if (quote) {
            const equipment = quote.equipment[0];
            const baseCoverage = equipment.coverages
                .filter(coverage => coverage.coverage_type === 'BASE');
            const ewCoverage = equipment.coverages.find(coverage => coverage.coverage_type === 'EW');
            const ratingType = quote.rating_type;
            const quoteForm = {
                volvo_ship_date: equipment.manufacturer_ship_date,
                customer_name: quote.customer?.name ?? null,
                quote_type: quote.quote_type,
                sale_type: ewCoverage!.quote_type,
                use_type: ewCoverage!.use_type,
                manufacturer: equipment.manufacturer,
                equipment_type: equipment.equipment_type,
                model: equipment.model,
                equipment_status: equipment.status ? equipment.status.id : null,
                model_year: equipment.year,
                ...(baseCoverage.length > 0 ? {
                    warranty_start_date: !baseCoverage[0].unknown_effective ? this.formatDate(new Date(baseCoverage[0].effective_date)) : null,
                    warranty_end_date: !baseCoverage[0].unknown_expiration ? this.formatDate(new Date(baseCoverage[0].expiration_date)) : null,
                } : {
                    warranty_start_date: null,
                    warranty_end_date: null
                }),
                ...(baseCoverage ? {
                    warranty_term: baseCoverage.map(coverage => {
                        return { term: coverage.term, hours: (coverage.hours), coverage: coverage.coverage }
                    })
                } : { warranty_term: [] }),
                current_hours: equipment.hours,
                plan_term: {
                    term: ewCoverage!.term,
                    hours: ewCoverage!.hours,
                },
                retail_value: parseFloat(equipment.value + ''),
                rating_type: ratingType ?? '',
                //is_presale: this.isPresale,
                presale_number: presaleValue
            };

            return quoteForm;
        }
        throw new Error('Unable to generate request data.')

    }

    public updatePlanterm(quote: object, id: number): Observable<Quote> {

        console.log(this.currentQuote.value)
        return this.http.patch<Quote>(`api/v2/quotes/${id}`, quote)
            .pipe(
                tap(quote => {
                    const previousQuote = this.currentQuote.value
                    this.currentQuote.next(quote);
                    this.ttmIncluded.next(quote.include_ttm);
                    this.setTTMCOverages(quote);
                    this.setTTMMFRCoverages(quote);
                    this.setEwCoverages(quote);

                    const quoteItemIndex = (this.quotesQueue.value).findIndex(item => item.id === id);
                    if (quoteItemIndex > -1) {
                        const queue = (this.quotesQueue.value).map(item => {
                            if (item.id === id) {
                                item.quote_expiration = quote.quote_expiration;
                            }
                            return item;
                        });
                        this.quoteItemUpdate.emit();
                    } else {
                        const queueItem: QuoteItem = this.createQueueItem(quote);

                        console.log(queueItem)
                    }
                    console.log('quote', quote)
                })
            )
    }

    public queueItemFactory(quote: Quote) {
        return this.createQueueItem(quote);
    }

    private createQueueItem(quote: Quote): QuoteItem {
        // console.log('test',quote.quote_expiration)
        return {
            id: quote.id,
            quote_expiration: quote.quote_expiration,
            quote_number: quote.quote_number,
            date_submitted: quote.date_submitted,
            include_ttm: quote.include_ttm,
            is_presale: quote.is_presale,
            customer: (quote.customer) ? {
                name: quote.customer?.name,
                id: quote.customer?.id
            } : null,
            equipment: (quote.equipment).map(equipment => {
                return {
                    id: equipment.id,
                    manufacturer: equipment.manufacturer,
                    model: equipment.model,
                    year: '' + (equipment.year),
                    equipment_type: equipment.equipment_type,
                    equipment_name: `${equipment.year} ${equipment.manufacturer} ${equipment.model} ${equipment.equipment_type}`
                };
            }),
            documents: (quote.documents).map(doc => {
                return {
                    name: doc.name,
                    document_key: doc.document_key,
                    path: doc.path
                };
            })
        };
    }

    private setTTMCOverages(quote: Quote) {
        const ttmCoverages = quote.equipment[0].coverages.filter(current => current.coverage_type === 'TTM');
        this.ttmCoverages.next(ttmCoverages);
    }

    private setEwCoverages(quote: Quote) {
        const ewCoverages = quote.equipment[0].coverages.filter(current => current.coverage_type === 'EW');
        this.ewCoverages.next(ewCoverages);
    }

    private setTTMMFRCoverages(quote: Quote) {
        const ttmMfrCoverages = quote.equipment[0].coverages.filter(coverage => coverage.coverage_type === 'TTM-MFR')
        this.ttmMfrCoverages.next(ttmMfrCoverages)
    }

    public ttmIncluded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public ewCoverages: BehaviorSubject<Coverage[]> = new BehaviorSubject<Coverage[]>([]);
    public ttmCoverages: BehaviorSubject<Coverage[]> = new BehaviorSubject<Coverage[]>([]);
    public ttmMfrCoverages: BehaviorSubject<Coverage[]> = new BehaviorSubject<Coverage[]>([]);
    public updateAddOn(id: number, data: any, addon: 'ttm' = 'ttm'): Observable<Quote> {
        return this.http.patch<Quote>(`api/v1/espp/quotes/${id}/ttm`, { ...data })
            .pipe(
                tap(quote => {
                    this.ttmIncluded.next(quote.include_ttm);
                    this.setEwCoverages(quote);
                    this.setTTMCOverages(quote);
                    this.setTTMMFRCoverages(quote);
                })
            )
    }

    public deleteQuote(id: number): Observable<Quote | null> {
        if (confirm('Are you sure you want to delete this quote.')) {
            return this.http.delete<Quote>(`api/v1/espp/quotes/${id}`)
                .pipe(
                    tap(quote => {
                        const currentValue = this.quotesQueue.value;
                        const deletedQuoteIndex = currentValue.findIndex(queueQuote => queueQuote.id === id)
                        currentValue.splice(deletedQuoteIndex, 1);
                        this.quotesQueue.next(currentValue);
                    })
                )
        }
        return of(null)
    }

    /**
     *
     * @param quoteId
     */
    downloadExcel(quoteId: number) {
        let thefile: Blob | null = null;
        this.http.get(`api/v2/export/${quoteId}/excel`, { responseType: 'blob' }).subscribe(data => {
            thefile = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
            //TODO refactor blob logic to another method for global use
            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.style.display = 'none';

            if (thefile !== null) {
                try {
                    const quote = this.currentQuote.value;
                    let url = window.URL.createObjectURL(thefile);
                    anchorElement.href = url;
                    anchorElement.download = (quote? quote.quote_number.toLocaleLowerCase() : 'quote_export')+'.xlsx';
                    anchorElement.click();

                    window.URL.revokeObjectURL(url);
                    //window.open(url);
                } catch (e) {
                    this.epgDialog.confirm('Pop-up Notice', 'Please enable your Pop-ups and try again.', 'OK')
                }
            }
        },
            (errorResponse: HttpErrorResponse) => {
                if (errorResponse.status == 403) {
                    this.epgDialog.confirm('Invalid Permissions', 'You do not have permission to download this file.', 'OK')
                }
            });
    }

    downloadPDF(url: string) {
        window.open(url, '_blank')
        // this.http.get(url.substring(1),{ responseType: 'arraybuffer'}).subscribe(
        //     (response: any) =>{
        //         this.downLoadFile(response, "application/ms-excel")
        //     }
        // )
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }

    //TODO: search implementation
    public search(term: string): Observable<QuoteResponse> {
        const submitted = (window.location.pathname).includes('submitted_applications');
        console.log(submitted)
        let params = new HttpParams();
        params = params.append('term', term)
        params = params.append('type', !submitted ? 'quotes' : 'submitted');
        return this.http.get<QuoteResponse>('api/v1/espp/quotes/search', { params })
            .pipe(
                tap(quotes => {
                    //this.quotesQueue.next(quotes.data);
                }),
                catchError(errorResponse => throwError(errorResponse))
            )
    }

    private clearQueue() {
        this.quotesQueue.next([]);
    }

    public addQuotes(quotes: Quote[]) {
        const current = this.quotesQueue.value;
        this.quotesQueue.next([
            ...quotes.map(quote => this.createQueueItem(quote)),
            ...current
        ]);
    }

    public resetQueue(): void {
        this.quotesQueue.next([]);
    }


    //quote coverage
    public deleteCoverage(quoteID: number, coverageID: number) {
        return this.coverageService.delete(quoteID, coverageID)
    }

    //apply for coverage
    public submitForCoverage(quoteID: number, requestData: ApplicationRequest): Observable<Quote> {
        return this.http.post<Quote>(`api/v1/espp/quotes/${quoteID}/apply`, requestData)
            .pipe(
                tap(quote => {
                    const queue = this.quotesQueue.value
                    this.currentQuote.next(quote);
                    const quoteIndex = queue.findIndex(item => item.id === quote.id);
                    if (quoteIndex >= 0) {
                        //queue[quoteIndex] = quote
                        queue.splice(quoteIndex, 1)
                        this.quotesQueue.next(queue)
                    }
                })
            )
    }

    public openFileModal(id) {
        this.modal.open(QuoteFileUploadComponent, { data: { id } })
    }

    public openRenrollModal() {
        return this.epgDialog.confirm(
            'Quote is Expired.',
            'Would you like to refresh the expired quote?',
            'OK',
            'Cancel');
    }

    public openPresaleExpiredModal() {
        return this.epgDialog.confirm(
            'Quote is Expired.',
            'This is a presale please regenrate if you want to apply for coverage.',
            'OK');
    }

    public openPresaleErrorModal() {
        return this.epgDialog.confirm(
            'Order Number is Required.',
            'An order number or work order number is required to lock in a presale quote.',
            'OK');
    }

    public addFile(id: number, file, category: FileCategory): Observable<File> {
        let formData = new FormData();
        formData.append('file_category', category);
        formData.append('file', file);
        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
            observe: "events",
        };
        //@ts-ignore
        return this.http.post<ArrayBuffer>('api/v1/espp/quotes/' + id + '/files', formData, options)
            .pipe(
                tap(data => {
                    if (data['type'] === HttpEventType.Response) {
                        console.log('res', data['body'])
                        //const file = data['body'] as File
                        //const claim = this.currentClaim.value
                        //if (claim?.id == id) {
                        //claim.files.push(file)
                        //this.currentClaim.next(claim)
                        //}
                        // const newClaims = (this.claims.value).map( claim => {
                        //     if (claim.id == id ) {
                        //         return {
                        //             ...claim,
                        //             ...{file_count: ++claim.file_count}
                        //         }
                        //     }
                        //     return claim
                        // })
                        //this.claims.next(newClaims)
                    }
                })
            )
    }


    public updatingCoverages = new BehaviorSubject<Array<number>>([]);
    public updateCoverage(quoteID: number, coverageData: Array<{ equipment_coverage_id: number, retail_cost: number, markup_type: MarkupType, markup_amount: number }>): Observable<Array<{ equipment_coverage_id: number, retail_cost: number, markup_type: MarkupType, markup_amount: number }>> {
        this.updatingCoverages.next(
            coverageData.map(coverage => coverage.equipment_coverage_id)
        );
        const quote = this.currentQuote.value;
        return this.http.patch<Array<{ equipment_coverage_id: number, retail_cost: number, markup_type: MarkupType, markup_amount: number, coverage: string, premium: number, customer_cost_addons: number }>>(`api/v1/espp/quotes/${quoteID}/coverages`, coverageData)
            .pipe(
                catchError(errorResponse => {
                    this.updatingCoverages.next(
                        []
                    );
                    return throwError(errorResponse);
                }),
                tap(coverages => {

                    const currentEwCoverages = this.ewCoverages.value;
                    coverages.forEach(coverage => {
                        const ewCoverage = (this.ewCoverages.value).findIndex(current => current.id == coverage.equipment_coverage_id);
                        if (ewCoverage >= 0) {
                            currentEwCoverages[ewCoverage].retail_cost = coverage.retail_cost;
                            currentEwCoverages[ewCoverage].customer_cost = coverage.retail_cost;
                            currentEwCoverages[ewCoverage].customer_cost_addons = coverage.customer_cost_addons;
                            currentEwCoverages[ewCoverage].markup_amount = coverage.markup_amount;
                            currentEwCoverages[ewCoverage].markup_type = coverage.markup_type;
                        }
                    });
                    this.ewCoverages.next(currentEwCoverages);
                    if (quote) {
                        quote.include_ttm = this.ttmIncluded.value;
                        const ttmMfrCoverages = quote.equipment[0].coverages.filter(coverage => coverage.coverage_type === 'TTM-MFR')
                        quote.equipment[0].coverages = quote.equipment[0].coverages.map(coverage => {
                            const updatedCoverage = currentEwCoverages.find(c => c.id == coverage.id);
                            if (updatedCoverage) {
                                return updatedCoverage;
                            }
                            return coverage;
                        });
                        quote.equipment[0].coverages = [
                            ...quote.equipment[0].coverages.filter(coverage => coverage.coverage_type !== 'TTM'),
                            ...this.ttmCoverages.value,
                            ...ttmMfrCoverages,
                        ]
                        this.ttmCoverages.next(
                            this.ttmCoverages.value
                        )
                        this.ttmMfrCoverages.next(ttmMfrCoverages)
                    }

                    this.currentQuote.next(quote);
                    this.updatingCoverages.next(
                        []
                    );
                })
            )
    }

    public getMessages(quoteID: number, coverages: number[]) {
        let params = new HttpParams();
        params = params.append('coverages', coverages.join(','))
        return this.http.get<string[]>(`api/v1/espp/quotes/${quoteID}/messages`, { params })
    }

    public checkIfDetailsOpened() {
        let temp = false
        this.isDetailsOpen.subscribe(theData => {
            temp = theData
        })
        return temp
    }

    public getUsers(){

        // api/v2/user/search-by-dealership/693?filter=robert
        let tempData:any
        return this.http.get<string[]>(`api/v2/user/search-by-dealership`).subscribe((data) => {
            return data
        }, error => {
            console.log('error', error);
        });
        
    }

    public getResultForSearchUsers(){

        // api/v2/user/search-by-dealership/filter=robert
        // api/v2/user/search-by-dealership/693?filter=robert
        let theAPI = this.http.get<string[]>(`api/v2/user/search-by-dealership?filter=robert`).subscribe((data) => {
            console.log('data', data);
           
        }, error => {
            console.log('error', error);
            
        });
        

        // console.log('theAPI', theAPI);
        
        return
        return this.http.get<string[]>(`api/v2/dealership/search/?filter=test`)
    }
    
    public getDealership(){
        //http://dev-espp-api.epgins.com/api/v2/dealership/search/?filter=test

        // let theAPI = this.http.get<string[]>(`api/v2/dealership/search/?filter=test`).subscribe((data) => {

        // return this.http.get<string[]>(`api/v2/dealership/search/`).subscribe((data) => {
        //     console.log('data', data);
           
        // }, error => {
        //     console.log('error', error);
            
        // });
        

        // // console.log('theAPI', theAPI);
        
        // return
        return this.http.get<string[]>(`api/v2/dealership/search/`)
        return this.http.get<string[]>(`api/v2/dealership/search/?filter=test`)
    }
    public getResultForSearchDealership(){
        //http://dev-espp-api.epgins.com/api/v2/dealership/search/?filter=test

        //http://dev-espp-api.epgins.com/api/v2/user/search-by-dealership
        let theAPI = this.http.get<string[]>(`api/v2/dealership/search/?filter=test`).subscribe((data) => {
            console.log('data', data);
           
        }, error => {
            console.log('error', error);
            
        });
        

        // console.log('theAPI', theAPI);
        
        return
        return this.http.get<string[]>(`api/v2/dealership/search/?filter=test`)
    }
    public getFilteredQuote(){
        
        // let testTheAPI = this.http.get<string[]>(`api/v1/espp/quotes?users[]=3&users[]=118&dealerships[]=693&dateRange[beginDate]=01-01-2023&dateRange[endDate]=02-01-2023&type=quotes`).subscribe((data) => {
        // let testTheAPI = this.http.get<string[]>(`api/v1/espp/quotes?users[]=3&users[]=118&dealerships[]=693&type=quotes`).subscribe((data) => {
        let testTheAPI = this.http.get<string[]>(`api/v1/espp/quotes?&type=quotes&users[]=3&users[]=118&dealerships[]=693&quoteTypes[]=presale`).subscribe((data) => {
            console.log('data', data);
           
        }, error => {
            console.log('error', error);
            
        });
        

        // console.log('theAPI', theAPI);
        
        return
        return this.http.get<string[]>(`api/v2/dealership/search/?filter=test`)
    }

}

export interface Deserializable {
    deserialize(input: any): this;
}

class Model<T> implements Deserializable {
    public id!: number;

    deserialize(input: T): this {
        Object.assign(this, input)
        return this;
    }

    public get key() {
        return this.id;
    }

}

class Factory {
    create<T>(type: (new () => T)): T {
        return new type();
    }
}


class HasMany<T, M extends Model<T>> {
    public data: M[] = Array<M>();
    constructor(model: (new () => M), data: T[]) {
        this.data = (data ?? []).map(data => ((new Factory().create(model)).deserialize(data)))
    }

    public value(): M[] {
        return this.data;
    }
}

class HasOne<T> {
    public data: Model<T> | null = null;
    constructor(model: Model<T>, data: any) {
        if (!data) return;
        this.data = model.deserialize(data)
    }

    public value(): any {
        // console.log(this.data)
        return this.data;
    }
}

const groupBy = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);


export class QuoteModel extends Model<Quote> implements Quote {
    public quote_number!: string;
    public quote_expiration!: string;
    public date_submitted!: string | null;
    public po_number!: string;
    public customer?: { id: number; name: string; address: string; city: string; state: string; zip: number; country: string; phone: string; } | undefined;
    public user?: { id: number; first_name: string; last_name: string; email: string; } | undefined;
    public equipment!: Equipment[];
    public documents!: { [key: string]: string; }[];
    public dealership?: Dealership | undefined;
    public quote_type!: string;
    public contacts!: { name: string; phone: string; email: string; id: number; }[];
    public rating_type!: string;
    public is_presale: boolean = false;
    public plan_number: string | null = null;
    public addons: string[] = [];

    constructor(
        quote: Quote
    ) {
        super();
        this.deserialize(quote)
    }
    mps_sync_group!: MPSSyncGroup | null;
    additional_users!: number[];
    invoice_code!: string | null;
    [x: string]: any;
    presale_number!: string | null;
    messages!: { category: string | null, comment: string }[];
    include_ttm!: boolean;
    comments!: string[];
    currency!: string | null;

    public get quoteNumber(): string {
        return this.quote_number;
    }

    public get expirationDate(): Date {
        return new Date(this.quote_expiration);
    }

    public get purchaseOrder(): string {
        return this.po_number;
    }

    public get primaryEquipment(): EquipmentModel {
        const equipment: EquipmentModel[] = new HasMany<Equipment, EquipmentModel>(EquipmentModel, this.equipment).value();
        return equipment[0];
    }

    public get coverages(): CoverageModel[] {
        if (this.primaryEquipment) {
            return new HasMany<Coverage, CoverageModel>(CoverageModel, this.primaryEquipment.coverages).value();
        }
        return [];
    }

    public get customerData(): CustomerModel | null {
        return new HasOne<Customer>(new CustomerModel(), this.customer).value();
    }

    public get salesPerson(): CustomerModel | null {
        return new HasOne<User>(new UserModel(), this.user).value();
    }

    public get ewCoverages(): CoverageModel[] {
        return this.coverages.filter(coverage => coverage.coverage_type == 'EW')
    }

    public get bewCoverage(): CoverageModel[] {
        return this.coverages.filter(coverage => coverage.coverage_type == 'BEW')
    }

    public get baseCoverages(): CoverageModel[] {
        return this.coverages.filter(coverage => coverage.coverage_type == 'BASE')
    }

    public get ttmCoverages(): CoverageModel[] {
        return this.coverages.filter(coverage => coverage.coverage_type == 'TTM')
    }

    get planTerm() {
        const ewCoverage = this.ewCoverages[0]
        const mainCoverage = ewCoverage.term + ' Months / ' + ewCoverage.hours + ' Hours';
        if (this.bewCoverage.length > 0) {
            const shortCov = this.bewCoverage[0]
            const shortCoverage = shortCov.term + ' Months / ' + shortCov.hours + ' Hours';
            return shortCoverage + ' & ' + mainCoverage
        }
        return mainCoverage
    }

    get useType() {
        const ewCoverage = this.ewCoverages
        if (ewCoverage.length > 0) {
            return ewCoverage[0].use_type
        }
        return null
    }

    get isExpired(): boolean {
        const today = new Date();
        const expiration = this.expirationDate;
        return today > expiration;
    }

    get hasTTM(): boolean {
        return this.primaryEquipment.has_ttm;
    }

    get ttmKey(): number {
        return this.primaryEquipment.ttm_key
    }

    get showTTM(): boolean {
        return this.hasTTM && this.ttmKey == 2
    }

    get program() {
        const ewCoverage = this.ewCoverages
        if (ewCoverage.length > 0) {
            return (this.primaryEquipment.new ? 'New' : 'Used') + ' ' + ewCoverage[0].quote_type
        }
        return null
    }
}

interface Customer { id: number; name: string; address: string; city: string; state: string; zip: number; country: string; phone: string; };
class CustomerModel extends Model<Customer> implements Customer {
    public name!: string;
    public address!: string;
    public city!: string;
    public state!: string;
    public zip!: number;
    public country!: string;
    public phone!: string;

    get customerName() {
        return this.name
    }

}
type MfrProgram = {
    id: number;
    name: string;
    current: boolean,
    active: boolean,
    dealer_id: number
}
export class UserModel extends Model<User> implements User {
    public id!: number;
    public first_name!: string;
    public last_name!: string;
    public email!: string;
    public permissions!: Permission[];
    public mfr_programs!: MfrProgram[];
    public password_expiration?: Date | null
    public locale: string = 'en-US'
    public staff: boolean = false;
    public view_level

    get name() {
        if (this.first_name && this.last_name) {
            return this.first_name + ' ' + this.last_name
        }
        return null
    }

}

class EquipmentModel extends Model<Equipment> implements Equipment {
    mfr_warranty!: string | null;
    status!: { name: string, id: number } | null;
    new!: boolean;
    manufacturer!: string;
    year!: number;
    model!: string;
    equipment_type!: string;
    serial_number!: string;
    pin_17?: string | undefined;
    value!: number;
    hours!: number;
    manufacturer_date!: Date;
    manufacturer_ship_date!: Date;
    coverages!: Coverage[];
    rates?: RateData | undefined;
    has_ttm!: boolean;
    ttm_key!: number;

    public get equipmentName() {
        return this.year + ' ' + this.manufacturer + ' ' + this.equipment_type + ' ' + this.model
    }

    get shipDate() {
        return new Date(this.manufacturer_ship_date)
    }

    get deliveryDate() {
        return new Date(this.manufacturer_date)
    }

    get retailValue() {
        return this.value
    }

    get rateData(): Rate[] {
        if (this.rates) {
            return this.rates.data
        }
        return []
    }

    get coverageRates(): { [key: string]: Rate[] } {
        return groupBy(this.rateData, 'coverage')
    }

    get equipmentCoverages() {
        return Object.keys(this.coverageRates)
    }

    public getRate(coverage: string): Rate[] {
        const rate = this.coverageRates[coverage];
        return rate.length == 0 ? [] : rate;
    }

    public rate(isCombo: boolean, coverage: string, term: number, hours: number) {
        // console.log(this.quote?.primaryEquipment.getRate(this.coverage))
        if (isCombo) {
            return (this.getRate(coverage)).find(rate => rate.coverage === coverage)
        }
        return (this.getRate(coverage)).find(rate => rate.term === term && rate.hours === hours)
    }

    public rateCost(isCombo: boolean, coverage: string, term: number, hours: number): number {
        const rate = this.rate(isCombo, coverage, term, hours);
        return rate ? rate.price : 0
    }

    // get totalCost(): number {
    //     if(!this.rate) return 0;
    //     return this.rateCost + this.ttm + this.dealerMarkup
    // }

    get ttmCoverages(): string[] {
        const coverages = this.coverages.filter(item => item.coverage_type == 'TTM').map(item => item.coverage)
        //@ts-ignore
        // let coverages = this.rateData.map(item => item.coverage).reduce((accumulator, currentValue) => {
        //     console.log(accumulator)
        //     if ((currentValue).includes('^^')) {
        //         let coverages = (currentValue.match(/[a-zA-Z]*/gm) ?? []).filter(item => item !== '');
        //         return [...accumulator, ...coverages]
        //     }
        //     return [...accumulator, ...[currentValue]]
        // }, [])
        return [...new Set([...coverages])];
    }

}

enum CoverageType {
    BASE = 'BASE',
    EW = 'EW',
    BEW = 'BEW',
    TTM = 'TTM'
}
class CoverageModel extends Model<Coverage> implements Coverage {
    sale_type_value!: string | null;
    customer_cost_addons!: number | null;
    public unknown_effective!: boolean;
    public unknown_expiration!: boolean;
    public sale_type!: { id: number; quote_type: string; quote_type_id: string; sale_type: string; } | null;

    public coverage_group!: number;
    public customer_cost!: number;
    public retail_cost!: number;
    public premium!: number;
    public total_charged!: number;
    public id!: number;
    public coverage_type!: string;
    public coverage!: string;
    public effective_date!: Date;
    public expiration_date!: Date;
    public term!: number;
    public hours!: number;
    public date_of_sale!: Date;
    public use_type!: string;
    public quote_type!: string;
    public deductible!: number;
    public ttm_limit!: number;
    public ttm_aggregate!: number;
    public has_ttm!: boolean;


    public markup_type!: MarkupType;
    public markup_amount!: number;

    get coverageName() {
        return this.coverage
    }
}


export interface ApplicationRequest {
    DateSubmitted: Date,
    Insured: {
        InsuredID?: number
        Name: string
        Address: string
        City: string
        State: string
        PostalCode: string
        PhoneNumber: string
    }
}