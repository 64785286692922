import { Injectable, Injector } from '@angular/core';
import { AppDataService } from 'src/app/core/app-data/app-data.service';
import {
  Credentials,
  MfrProgram,
  TokenInfo,
  User,
  OktaCredentials,
} from './authentication.types';
import { Observable } from 'rxjs';
import {
  APP_LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  PASSWORD_RESET,
  REFRESH_TOKEN,
} from 'src/app/shared/api.constants';
import { USER_KEY } from 'src/app/core/auth/constants';
import { Router } from '@angular/router';
import { Permission } from 'src/app/core/auth/permissions.types';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { PasswordResetParams } from './password-reset/password-reset.types';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _user: User | null = null;

  constructor(
    protected appData: AppDataService,
    private injector: Injector,
    private router: Router
  ) {}

  /**
   * @returns Observable
   * @memberof AuthenticationService
   */
  public login(credentials: Credentials): Observable<any> {
    return this.appData.post<any, any>(APP_LOGIN, {}, credentials);
  }
  /**
   * @returns Observable
   * @memberof AuthenticationService
   */
  public ssoLogin(credentials: OktaCredentials): Observable<any> {
    return this.appData.post<any, any>(APP_LOGIN, {}, { tokens: credentials });
  }
  /**
   * @returns Observable
   * @memberof AuthenticationService
   */
  public forgotPassword(email: { email: string }): Observable<any> {
    return this.appData.get<any>(FORGOT_PASSWORD, email);
  }
  /**
   * @returns void
   * @memberof AuthenticationService
   */
  public storeUser(userMetadata: User): void {
    this._user = userMetadata;
    sessionStorage.setItem(USER_KEY, JSON.stringify(userMetadata));
  }
  /**
   * @returns User
   * @memberof AuthenticationService
   */
  public get user(): User {
    const storedUser = sessionStorage.getItem(USER_KEY);
    const user: User = storedUser
      ? JSON.parse(sessionStorage.getItem(USER_KEY) || '')
      : this._user;
    if (storedUser && !this._user)
      this._user = JSON.parse(sessionStorage.getItem(USER_KEY) || '');
    return user;
  }
  /**
   * @returns Country Code
   * @memberof AuthenticationService
   */
  public get userCountryCode(): string {
    const locale = this.user.locale
    const countryCode = locale.split('-')[1]
    return countryCode
  }
  public get userLanguageCode(): string {
    const locale = this.user.locale
    const languageCode = locale.split('-')[0]
    return languageCode
  }
  /**
   * @returns token
   * @memberof AuthenticationService
   */
  public get token(): string {
    if (this.isAuthenticated) {
      return this.user.token_list.access_token;
    } else {
      return '';
    }
  }
  /**
   * @returns refresh token
   * @memberof AuthenticationService
   */
  public get refreshToken(): string {
    if (this.isAuthenticated) {
      return this.user.token_list.refresh_token;
    } else {
      return '';
    }
  }
  /**
   * @returns Observable
   * @memberof AuthenticationService
   */
  public tokenRefresh(): Observable<any> {
    const refresh_token = this.refreshToken;
    return this.appData.post<any, any>(REFRESH_TOKEN, { refresh_token }, {});
  }
  /**
   * @returns void
   * @memberof AuthenticationService
   */
  public updateToken(newToken: TokenInfo) {
    const user = this.user;
    this.storeUser({ ...user, token_list: newToken });
  }
  /**
   * @returns void
   * @param userMetadata User
   * @memberof AuthenticationService
   */
  public handleAuthentication(userMetadata: User) {
    this.storeUser(userMetadata);
    this.goToApp();
  }
  /**
   * @returns boolean
   * @memberof AuthenticationService
   */
  public get isAuthenticated(): boolean {
    return this.user ? true : false;
  }
  /**
   * @returns MfrProgram | undefined
   * @memberof AuthenticationService
   */
  public get currentMfrProgram(): MfrProgram | undefined {
    return this.user.mfr_programs.find((program) => program.current);
  }
  /**
   * @return void
   * @memberof AuthenticationService
   */
  public logout(): void {
    this._user = null;
    this.appData.post<any, any>(LOGOUT, {}, {}).subscribe(
      (response) => {
        sessionStorage.removeItem(USER_KEY);
        this.router.navigate(['/login']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /**
   * @returns void
   * @memberof AuthenticationService
   * @description Navigates to authorized section
   */
  public goToApp(): void {
    if (!this.isAuthenticated) {
      this.router.navigate(['/login']);
      return;
    }
    /**
     * Obtain Authorization service instance without circular dependency
     */
    const authorizationService = this.injector.get(AuthorizationService);
    const permissions = this.user.permissions.map(
      (permission) => permission.name
    );
    authorizationService.setPermissions(permissions);

    const canQuote = authorizationService.hasPermission([
      Permission.CREATE_QUOTE,
      Permission.VIEW_QUOTES,
      Permission.READ_QUOTE,
    ]);
    const canProcessed = authorizationService.hasPermission([
      Permission.VIEW_ENROLLED_UNITS,
      Permission.VIEW_ENROLLED_UNIT,
    ]);
    const canClaim = authorizationService.hasPermission([
      Permission.CREATE_CLAIM,
      Permission.VIEW_CLAIMS,
      Permission.VIEW_CLAIM,
      Permission.READ_CLAIM,
    ]);

    let destination = this.router.createUrlTree(['/not_found']);

    if (canQuote) {
      destination = this.router.createUrlTree(['/apps', 'espp', 'quotes']);
      this.router.navigateByUrl(destination);
      return;
    }
    if (canProcessed) {
      destination = this.router.createUrlTree([
        '/apps',
        'espp',
        'enrolled_units',
      ]);
      this.router.navigateByUrl(destination);
      return;
    }
    if (canClaim) {
      destination = this.router.createUrlTree(['/apps', 'espp', 'claims']);
      this.router.navigateByUrl(destination);
      return;
    }
    this.router.navigateByUrl(destination);
  }
  /**
   * @returns Observable
   * @param reqBody
   * @memberof AuthenticationService
   */
  public passwordReset(reqBody: PasswordResetParams): Observable<any> {
    return this.appData.post<any, any>(PASSWORD_RESET, {}, reqBody);
  }

   /**
       * @returns boolean
       * @description Check if current user is logged in as ESPP user
       * @memberof AuthenticationService
       */
    public get isEPG(): boolean {
        let theResult = this.user.mfr_programs.find(program => program.name === 'EPG' && program.current === true)
        return theResult ? true : false
    }
}
